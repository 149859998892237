(() => {
	AOS.init();
})();

(() => {
	document.querySelectorAll('a[href^="#"]').forEach(anchor => {
	    anchor.addEventListener('click', function (e) {
	        e.preventDefault();

			const yOffset = -74; 
	        const element = document.querySelector(this.getAttribute('href'));
	        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

	        window.scrollTo({top: y, behavior: 'smooth'});
	    });
	});
})();

(() => {
	const video = document.getElementById('header-video');
	video.play();
})();

(async () => {
	const apiUrl = 'https://xton24.pl/wp-json/wc/v3/products/94?consumer_key=ck_025d4df3495000dbbc80927306d549b6daa931d9&consumer_secret=cs_253f8787f5b8670f6d054b6ae258cf161bb596d0';
	const reduceCostHeading = document.getElementById('reduce-cost-heading');
	await fetch(apiUrl)
		.then((response) => response.json())
		.then((data) => {
			const priceFields = document.querySelectorAll('.product-price');
			const pricePerService = document.getElementById('price-per-service');

			priceFields.forEach((item) => {
				item.innerHTML = data.price;
			});

			const costPerService = Math.ceil(((parseFloat(data.price) * 1.23) / 20));
			const costPerServiceString = `Obniż koszt chemii nawet do ${costPerService} PLN za 1&nbsp;usługę regenecji filtra DPF.`;

			pricePerService.innerHTML = costPerService.toString();

			reduceCostHeading.innerHTML = costPerServiceString;
		});
})();

(() => {
	const discountPopup = document.getElementById('discount-popup');

	if ( typeof discountPopup != 'undefined' && discountPopup != null ) {
		const openDiscountPopup = document.querySelectorAll('.open-discount-popup');
		const closeDiscountPopup = document.getElementById('close-discount-popup');
		const copyCouponBtn = document.getElementById('copy-coupon');
		const toggleClass = 'hidden';

		openDiscountPopup.forEach((button) => {
			button.addEventListener('click', (e) => {
				e.preventDefault();

				discountPopup.classList.remove(toggleClass);
				copyCouponBtn.classList.remove('text-xton-blue');
				copyCouponBtn.innerHTML = "Skopiuj do schowka";

				copyCouponBtn.addEventListener('click', (e) => {
					e.preventDefault();
					navigator.clipboard.writeText('dpfcleaner');
					copyCouponBtn.classList.add('text-xton-blue');
					copyCouponBtn.innerHTML = "Skopiowano kod";
					copyCouponBtn.removeEventListener('click');
				});
			});
		});

		closeDiscountPopup.addEventListener('click', (e) => {
			e.preventDefault();

			discountPopup.classList.add(toggleClass);
		});
	}
})();

(() => {
	const videoPopup = document.getElementById('video-popup');

	if ( typeof videoPopup != 'undefined' && videoPopup != null ) {
		const videoIframeUrl = 'https://www.youtube.com/embed/pWD5KO35bhw';
		const openDiscountPopup = document.getElementById('open-video-popup');
		const closeDiscountPopup = document.getElementById('close-video-popup');
		const toggleClass = 'hidden';

		openDiscountPopup.addEventListener('click', (e) => {
			e.preventDefault();
			const iframeElement = videoPopup.querySelector('#video-popup-iframe');
			iframeElement.src = videoIframeUrl;
			videoPopup.classList.remove(toggleClass);
		});

		closeDiscountPopup.addEventListener('click', (e) => {
			e.preventDefault();
			videoPopup.classList.add(toggleClass);
		});
	}
})();